import { Link } from 'gatsby';
import React from 'react';
import LegalLayout from '../components/layout/LegalLayout';
import Text from '../components/ui/Text';

const name = 'Slab, Inc.';
const updatedAt = 'Oct 12, 2023';
const website = <Link to="/">https://www.slab.com</Link>;
const privacy = <Link to="/privacy/">Privacy Policy</Link>;
const terms = <Link to="/terms/">Terms of Use</Link>;
const support = <a href="mailto:support@slab.com">support@slab.com</a>;
const mail = (
  <>
    440 North Barranca Ave #8017
    <br />
    Covina, CA 91723
    <br />
    USA
  </>
);

const Privacy = () => (
  <LegalLayout title="Privacy Policy">
    <section>
      <h1>Privacy Policy</h1>
      <p>Last updated: {updatedAt}</p>
    </section>
    <section>
      <h2>1. Introduction</h2>
      <p>
        Fallacy Labs, Inc. (doing business as Slab) (&quot;
        <u>Slab</u>,&quot; &quot;
        <u>we</u>
        &quot; or &quot;
        <u>us</u>
        &quot;) is committed to protecting your privacy. This Privacy Policy
        (our &ldquo;<strong>Privacy Policy</strong>&rdquo;) describes the types
        of information we may collect from you or that you may provide when you
        visit/use {website} (&ldquo;<strong>Website</strong>&rdquo;), software,
        documentation, and related services (collectively, &ldquo;
        <strong>Services</strong>&rdquo;) and our practices for collecting,
        using, maintaining, protecting, and disclosing that information.
      </p>
      <p>This policy applies to information we collect:</p>
      <ul>
        <li>through our Services;</li>
        <li>mobile and desktop applications;</li>
        <li>
          in email, text, and other electronic messages between you and Slab;
          and
        </li>
        <li>any of our platforms that may post this Privacy Policy.</li>
      </ul>
      <p>It does not apply to information collected by:</p>
      <ul>
        <li>
          us offline or through any other means, including on any other Service
          operated by Slab or any third-party (including our affiliates and
          subsidiaries)
        </li>
        <li>
          us or any of our affiliates or subsidiaries related to your or any
          other individual&rsquo;s employment or potential employment with us;
          or
        </li>
        <li>
          any third party including through any application or content
          (including advertising) that may link to or be accessible from our
          Services.
        </li>
      </ul>
      <p>
        Please read this Privacy Policy carefully to understand our policies and
        practices regarding your information and how we will treat it. If you do
        not agree with our policies and practices, your choice is to not use our
        Services. By accessing or using our Services, you agree to this Privacy
        Policy. This Privacy Policy may change from time to time (see{' '}
        <a href="#privacy-policy-changes">Changes to Our Privacy Policy</a>
        ). Your continued use of our Services after we make changes is deemed to
        be acceptance of those changes, so please check this Privacy Policy
        periodically for updates.
      </p>
      <p>
        Capitalized terms not defined in this Privacy Policy have the meaning as
        set forth in the Terms of Use for the Services, which can be found at{' '}
        {terms}.
      </p>
    </section>
    <section>
      <h2>2. Children Under the Age of 18</h2>
      <p>
        By using our Services, you consent to the use of your Personal
        Information as described in this Privacy Policy. If you are not of legal
        age to form a binding contract (in many jurisdictions, this age is 18),
        you may only use the Services and disclose information to us with your
        parent&rsquo;s or legal guardian&rsquo;s express consent. Our Services
        are not intended for children under 13 years of age. No one under age 13
        may provide any information to or on the Website. We do not knowingly
        collect Personal Data from children under 13. If you are under 13, do
        not use or provide any information on our Website or on or through any
        of its features, including your name, address, telephone number, or
        email address. If we learn we have collected or received Personal Data
        from a child under 13 without verification of parental consent, we will
        delete that information. If you believe we might have any information
        directly from a child under 13, please contact us at {support}.
      </p>
    </section>
    <section>
      <h2>3. Information We Collect About You and How We Collect It</h2>
      <p>
        Throughout this Privacy Policy, the term &ldquo;
        <strong>Personal Data</strong>&rdquo; or &ldquo;
        <strong>Personal Information</strong>&rdquo; means any information that
        identifies, relates to, describes, references, is capable of being
        associated with, or could reasonably be linked, directly or indirectly,
        with a particular individual or device. However, Personal Information
        does not include any deidentified or aggregated information.
      </p>
      <Text center>
        <h3>Collection of Information</h3>
      </Text>
      <u>
        <i>
          {' '}
          <h4>Personal Information</h4>
        </i>
      </u>
      <p>
        When registering to use the Services, we may require or ask you to
        provide certain personally identifiable information (these are referred
        to below as your &ldquo;<u>Personal Contact Information</u>&rdquo;). The
        Personal Contact Information that we require you to provide may include,
        but is not limited to, the following:
      </p>
      <ul>
        <li>E-mail</li>
        <li>Full name or alias (can be fictitious)</li>
        <li>Company name</li>
        <li>Company subdomain</li>
      </ul>
      <p>
        We may also ask you to provide certain other Personal Contact
        Information, which is not required and you may decline to provide. Such
        information may include, but is not limited to:
      </p>
      <ul>
        <li>Profile picture</li>
        <li>Title</li>
        <li>Job description</li>
      </ul>
      <p>
        When purchasing any of our Services, we will require you to provide
        financial and billing information, such as billing name and address, and
        credit card number (&ldquo;<u>Billing Information</u>&rdquo;). Your
        Personal Contact Information and your Billing Information, together with
        any other information we gather through the use of our Services that may
        be used to identify, contact, or locate you individually, are
        collectively referred to herein as your &ldquo;
        <u>Personal Information</u>.&rdquo;
      </p>
      <p>
        We may use your Personal Information to contact you with newsletters,
        marketing or promotional materials and other information that may be of
        interest to you. You may opt out of receiving any, or all, of these
        communications from us by following the unsubscribe link or instructions
        provided in any email we send. While there are limited circumstances in
        which we may give third parties access to your Personal Information, the
        purposes of which are described below, we do not permit any third
        parties with whom we may share your Personal Information to contact you
        with their own marketing or promotional materials.
      </p>
      <p>
        You may also be provided the ability to provide us with the name and
        email address of your coworkers to allow us to send them a referral link
        to sign up for our Services. Applicable law may require you obtain
        consent from your coworker before sharing their name and email address
        with us and authorizing us to communicate with them. Such communications
        may contain web beacons that provide us information about your
        coworkers&rsquo; interaction with the emails we send them. By submitting
        any third-party personal information to us and allowing us to
        communicate with such third party, you agree that you have all required
        authorization and consents required under applicable law.
      </p>
      <u>
        <i>
          {' '}
          <h4>Data, Diagnostic &amp; Login Information</h4>
        </i>
      </u>
      <p>
        You may be able to create, upload, publish, transmit, distribute,
        display, store or share information, data, text, graphics, video,
        messages or other materials using our Services (this is collectively
        referred to below as &ldquo;<u>Data</u>&rdquo;). Some of this Data may
        be stored and maintained on our servers.
      </p>
      <p>
        If you run into technical errors in the course of using the Services, we
        may automatically obtain a crash report along with certain logging
        information from your system documenting the error (&ldquo;
        <u>Diagnostic Information</u>&rdquo;). Such information may contain
        information regarding your Operating System version, hardware, browser
        version, and your e-mail address, if provided. In some instances, we may
        request your permission to obtain some of your user-generated content to
        which the technical error may relate. This is strictly optional. We will
        only use such content to diagnose the error and help improve our
        Services to avoid such errors in the future.
      </p>
      <p>
        Additionally, certain login information may be maintained in a cookie
        stored locally on your personal computing device (i.e. not on a server)
        in order to streamline the login process (&ldquo;
        <u>Login Information</u>&rdquo;).
      </p>
      <u>
        <i>
          {' '}
          <h4>Usage and Analytics Information</h4>
        </i>
      </u>
      <p>
        As you use our Services, we may also collect information through the use
        of commonly-used information-gathering tools, such as cookies, log
        files, Web tokens, and Web beacons. Collectively, this information is
        referred to as &ldquo;
        <u>Usage and Analytics Information</u>
        .&rdquo;
      </p>
      <Text center>
        <h3>
          Information We Collect Through Automatic Data Collection Technologies
        </h3>
      </Text>
      <p>
        As you navigate through and interact with our Services, we may use
        automatic data collection technologies to collect certain Personal Data
        about your interaction with our Services, including information about
        your equipment, browsing actions, and patterns:
      </p>
      <ul>
        <li>
          details of your interaction with our Services. This includes: browsing
          history, search history, traffic data, location data, logs,
          referring/exit pages, date and time of your interaction with our
          Services, error information, clickstream data, and other Services
          communication data and the resources that you access, use, or
          otherwise interact with while using our Services; and
        </li>
        <li>
          Information about your computer and internet connection, i.e., your IP
          address, operating system, and browser type.
        </li>
      </ul>
      <p>
        The information we collect automatically may include Personal Data, or
        we may maintain it or associate it with Personal Data we collect in
        other ways or receive from third parties. It helps us to improve our
        Services and to deliver a better and more personalized service by
        enabling us to:
      </p>
      <ul>
        <li>estimate our audience size and usage patterns;</li>
        <li>store information about your preferences;</li>
        <li>customize our Services according to your individual interests;</li>
        <li>speed up your searches; and</li>
        <li>recognize you when you return to our Services.</li>
      </ul>
      <p>
        The technologies we use for this automatic data collection may include:
      </p>
      <p>
        <strong>Cookies (or browser cookies).</strong> A cookie is a small file
        placed on the hard drive of your computer. Our Services may use both
        session cookies (which expire once you close your browser) and
        persistent cookies (which stay on your computer until you delete them)
        to provide you with a more personal and interactive experience with our
        Services. Unless you have adjusted your browser setting so that it will
        refuse cookies, our system will issue cookies when you direct your
        browser to our Services. You can set your browser to refuse all or some
        browser cookies, or to alert you when cookies are being sent. However,
        if you do not consent to our use of cookies or select this setting you
        may be unable to access certain parts of our Services. You can find more
        information about cookies at{' '}
        <a
          href="https://www.allaboutcookies.org"
          target="_blank"
          rel="noreferrer"
        >
          https://www.allaboutcookies.org
        </a>{' '}
        and{' '}
        <a href="http://youronlinechoices.eu" target="_blank" rel="noreferrer">
          http://youronlinechoices.eu
        </a>
        .
      </p>
      <p>
        <strong>Session Cookies.</strong> Our use of cookies also includes
        &ldquo;session cookies.&rdquo; Each time you access the Services, a
        session cookie containing an encrypted, unique identifier is placed on
        your browser. These session cookies allow us to uniquely identify you
        when you use the Services and track which pages of the Services you
        access. Session cookies are required to use the Services.
      </p>
      <p>
        <strong>Log Files.</strong> As is true of most websites, we gather
        certain information automatically to analyze trends in the aggregate and
        administer the Services. This information may include your Internet
        Protocol (IP) address (or the proxy server you use to access the World
        Wide Web), your browser type, the pages and files you viewed, your
        operating system, and date/time stamps associated with your usage. Due
        to internet communications standards, when you visit or use our websites
        and Services, We automatically receive the URL of the website from which
        you came and the website to which you go when you leave our website.
        This information is used to analyze overall trends, to help us improve
        the Services, to track and aggregate non-personal information, and to
        provide the Services. For example, we use IP addresses to monitor the
        regions from which customers and visitors navigate the Sites. We also
        collects IP addresses from customers when they log into the Services as
        part of the &ldquo;IP Restriction&rdquo; security feature as well as to
        maintain audit logs.
      </p>
      <p>
        <strong>Web Beacons.</strong> Pages of our Services may contain small
        electronic files known as web beacons (also referred to as clear gifs,
        pixel tags, and single-pixel gifs) that permit Slab, for example, to
        count users who have visited those pages or opened an email and for
        other related Service statistics (for example, recording the popularity
        of certain Service content and verifying system and server integrity).
      </p>
    </section>
    <section>
      <h2>4. How We Use Your Information</h2>
      <p>
        We use information that we collect about you or that you provide to us,
        including any Personal Data:
      </p>
      <ul>
        <li>
          to provide and personalize our Services and their content to you;
        </li>
        <li>
          to provide you with information, products, or services that you
          request from us;
        </li>
        <li>
          to process your requests, purchases, transactions, and payments and
          prevent transactional fraud;
        </li>
        <li>
          to support, develop, troubleshoot, and debug our Services, products,
          and platform;
        </li>
        <li>
          to provide you with support and to respond to your inquiries,
          including to investigate and address your concerns and monitor and
          improve our responses;
        </li>
        <li>
          to personalize your Service experience and to deliver content and
          product and service offerings relevant to your interests, including
          targeted offers and ads through via email or text message (with your
          consent, where required by law);
        </li>
        <li>
          to help maintain the safety, security, and integrity of our Services,
          products and services, databases and other technology assets, and
          business;
        </li>
        <li>
          to detect security incidents, protect against malicious, deceptive,
          fraudulent, or illegal activity, and prosecute those responsible for
          that activity;
        </li>
        <li>
          for internal testing, research, analysis, and product development,
          including to develop and improve our Services, and to develop,
          improve, or demonstrate our products and services;
        </li>
        <li>
          for debugging to identify and repair errors that impair existing
          intended functionality;
        </li>
        <li>
          to notify you about changes to our Services or any products or
          services we offer or provide though them;
        </li>
        <li>
          in any other way we may describe when you provide the information;
        </li>
        <li>to fulfill any other purpose for which you provide it; and</li>
        <li>for any other purpose with your consent.</li>
      </ul>
      <p>
        Please note that if you are registering for or using the Services as an
        administrator on behalf of an organization, you may be able to view the
        activity and/or content associated with your associated users&rsquo; use
        of the Services, including, but not limited to, their Personally
        Information.
      </p>
      <p>
        If we plan to use your Personal Information in the future for any other
        purposes not identified above, we will only do so after informing you by
        updating this Privacy Policy.
      </p>
    </section>
    <section>
      <h2>5. Choices About How We Use and Disclose Your Information</h2>
      <p>
        We offer you choices on how you can opt out of our certain uses and
        sharing of your Personal Information. As a general rule, you cannot opt
        out of our collection, use and sharing of Personal Information to the
        extent it is necessary to provide the Service or related Services,
        features and functionality to you.
      </p>
      <p>
        Please note that internet browsers allow you to change your cookie
        settings. These settings are usually found in the &lsquo;options&rsquo;
        or &lsquo;preferences&rsquo; menu of your internet browser. However, if
        you use your browser settings to block all cookies (including strictly
        necessary cookies), you may not be able to access or use all or areas
        and aspects of our Services. You can change the cookie settings that
        will be placed when you use our Services by changing the settings on
        your browser. You can also block cookies by activating the setting on
        your browser that allows you to refuse the setting of all or some
        cookies.
      </p>
      <p>
        You can generally opt out of receiving interest-based advertisements
        from third party advertisers and ad networks who are members of the
        Network Advertising Initiative (NAI) or who follow the Digital
        Advertising Alliance&rsquo;s Self-Regulatory Principles for Online
        Behavioral Advertising by visiting the opt out pages on the NAI website
        and DAA website.
      </p>
      <p>
        We do not control third parties&rsquo; collection or use of your
        information to serve interest-based advertising. However, these third
        parties may provide you with ways to choose not to have your information
        collected or used in this way. You can learn more about interest-based
        advertisements and your opt-out rights and options from members of the
        Network Advertising Initiative (&ldquo;<strong>NAI</strong>&rdquo;) on
        its platform (
        <a
          href="http://www.networkadvertising.org"
          target="_blank"
          rel="noreferrer"
        >
          www.networkadvertising.org
        </a>
        ) and from members of the Digital Advertising Alliance on its platform (
        <a href="http://www.aboutads.info" target="_blank" rel="noreferrer">
          www.aboutads.info
        </a>
        ).
      </p>
    </section>

    <section>
      <h2>6. Do Not Track Signals</h2>
      <p>
        We also may use automated data collection technologies to collect
        information about your online activities over time and across
        third-party websites or other online services (behavioral tracking).
        Some web browsers permit you to broadcast a signal to websites and
        online services indicating a preference that they &ldquo;do not
        track&rdquo; your online activities. At this time, we do not support
        &ldquo;Do Not Track&rdquo;. Do Not Track is a preference you can set in
        your web browser to inform websites that you do not want to be tracked.
        You can enable or disable Do Not Track by visiting the Preferences or
        Settings page of your web browser.
      </p>
    </section>
    <section>
      <h2>7. Disclosures and Transfers</h2>
      <p>
        We have put in place contractual and other organizational safeguards
        with our agents to ensure a proper level of protection of your Personal
        Information. In addition to those measures, we will not disclose or
        transfer your Personal Information to third parties without your
        permission, except as specified in this Privacy Policy.
      </p>
      <p>
        Please note that if you are using the Services at the request or as part
        of an organization, your organization&rsquo;s account administrator may
        be able to view certain activity and content associated with use of the
        Services, including, but not limited to, elements of your Personally
        Information.
      </p>
      <p>
        Your information, including Personal Data, may be transferred
        to&mdash;and maintained on&mdash; computers located outside of your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from your jurisdiction.
      </p>
      <p>
        If you are located outside the United States and choose to provide
        information to us, please note that we transfer the data, including
        Personal Data, to the United States and process it there.
      </p>
      <p>
        Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.
      </p>
      <p>
        We will take all steps reasonably necessary to ensure that your data is
        treated securely and in accordance with this Privacy Policy and no
        transfer of your Personal Data will take place to an organization or a
        country unless there are adequate controls in place including the
        security of your data and other personal information.
      </p>
      <p>
        From time to time we may employ third parties to help us provide and/or
        improve the Services. These third parties may have limited access to
        databases of user information or registered member information solely
        for the purpose of helping us to provide and/or improve the Services and
        they will be subject to contractual restrictions prohibiting them from
        using the information about our users for any other purpose. Such agents
        or third parties do not have any rights to use Personal Information
        beyond what is absolutely necessary to assist us.
      </p>
      <p>
        If we are involved in a merger, acquisition or asset sale, your Personal
        Information may be transferred. We will provide notice before your
        Personal Information is transferred and becomes subject to a different
        Privacy Policy.
      </p>
    </section>
    <section>
      <h2>8. Data Security</h2>
      <p>
        We have implemented measures designed to secure your Personal Data from
        accidental loss and from unauthorized access, use, alteration, and
        disclosure.
      </p>
      <p>
        The safety and security of your information also depends on you. We ask
        you not to share sensitive information with us.
      </p>
      <p>
        Unfortunately, the transmission of information via the internet is not
        completely secure. Although we do our best to protect your Personal
        Data, we cannot guarantee the security of your Personal Data transmitted
        to any of our Services. Any transmission of Personal Data is at your own
        risk. We are not responsible for circumvention of any privacy settings
        or security measures deployed on our Services.
      </p>
    </section>
    <section>
      <h2>9. Consent to Processing of Personal Data in the United States</h2>
      <p>
        In order to provide our Services, products, and services to you, we may
        send and store your Personal Data outside of the country where you
        reside or are located, including to countries that may not or do not
        provide an equivalent level of protection for your Personal Data. Your
        Personal Data may be processed and stored in the United States and
        federal, state, and local governments, courts, or law enforcement or
        regulatory agencies in the United States may be able to obtain
        disclosure of your information through the laws of the United States. By
        using our Services, you represent that you have read and understood the
        above and hereby consent to the storage and processing of Personal Data
        outside the country where you reside or are located, including in the
        United States.
      </p>
      <p>
        Your Personal Data is transferred by Slab to another country only if it
        is required or permitted under applicable data protection law and
        provided that there are appropriate safeguards in place to protect your
        Personal Data. To ensure your Personal Data is treated in accordance
        with this Privacy Policy when we transfer it to a third party, Slab uses
        Data Protection Agreements or equivalent clauses between Slab and all
        other recipients of your Personal Data.
      </p>
    </section>
    <section>
      <h2>10. Accessing, Correcting, and Deleting Your Personal Data</h2>
      <p>
        You have the right to access the Personal Information we hold about you
        in order to verify the Personal Information we have collected in respect
        to you and to have a general account of our uses of that information.
        Upon receipt of your written request, we will provide you with a copy of
        your Personal Information, although in certain limited circumstances we
        may not be able to make all relevant information available to you, such
        as where that information also pertains to another user. In such
        circumstances we will provide reasons for the denial to you upon
        request. We will endeavor to deal with all requests for access and
        modifications in a timely manner.
      </p>
      <p>
        We will make every reasonable effort to keep your Personal Information
        accurate and up-to-date, and we will provide you with mechanisms to
        correct, amend, delete or limit the use of your Personal Information. As
        appropriate, this amended Personal Information will be transmitted to
        those parties to which we are permitted to disclose your information.
        Having accurate Personal Information about you enables us to give you
        the best possible service.
      </p>
      <p>In certain circumstances, you have the right:</p>
      <ul>
        <li>
          To access and receive a copy of the Personal Data we hold about you
        </li>
        <li>To rectify any Personal Data held about you that is inaccurate</li>
        <li>To request the deletion of Personal Data held about you</li>
      </ul>
      <p>
        You have the right to data portability for the information you provide
        to us. You can request to obtain a copy of your Personal Information in
        a commonly used electronic format so that you can manage and move it.
      </p>
      <p>
        Please note that we may ask you to verify your identity before
        responding to such requests.
      </p>
      <p>
        Under California Civil Code Sections 1798.83-1798.84, California
        residents are entitled to ask us for a notice identifying the categories
        of Personal Information which we share with our affiliates and/or third
        parties for marketing purposes, and providing contact information for
        such affiliates and/or third parties. If you are a California resident
        and would like a copy of this notice, please submit a written request
        to: {support}.
      </p>
      <p>
        If you request that we delete your User Content from the Service copies
        of your User Content may remain viewable in cached and archived pages,
        or might have been copied or stored by other Service users. Proper
        access and use of information provided on the Service, including User
        Content, is governed by our {terms}.
      </p>
      <p>
        The jurisdiction in which you are a resident or are located may provide
        you with additional rights and choices regarding your Personal Data.
        Please see{' '}
        <a href="#jurisdiction-privacy-rights">
          Jurisdiction-Specific Privacy Rights
        </a>{' '}
        for more information.
      </p>
    </section>
    <section>
      <h2 id="jurisdiction-privacy-rights">
        11. Jurisdiction-Specific Privacy Rights
      </h2>
      <p>
        The law in some jurisdictions may provide you with additional rights
        regarding our use of Personal Data. To learn more about any additional
        rights that may be applicable to you as a resident of one of these
        jurisdictions, please see the privacy addendum for your state that is
        attached to this Privacy Notice.
      </p>
      <Text center>
        <h3>YOUR CALIFORNIA PRIVACY RIGHTS</h3>
      </Text>
      <p>
        California Civil Code Section 1798.83 (California&rsquo;s &ldquo;Shine
        the Light&rdquo; law) permits users of our Website that are California
        residents and who provide Personal Data in obtaining products and
        services for personal, family, or household use to request certain
        information regarding our disclosure of Personal Data to third parties
        for their own direct marketing purposes. If applicable, this information
        would include the categories of Personal Data and the names and
        addresses of those businesses with which we shared your Personal Data
        with for the immediately prior calendar year (e.g., requests made in
        2022 will receive information regarding such activities in 2021). You
        may request this information once per calendar year. To make such a
        request, please send an email to {support}. California law permits
        minors under the age of 18 to request the removal of your User Content,
        subject to certain exceptions. If you are under the age of 18 in
        California, you may contact us using the{' '}
        <a href="#contact-information">Contact Information</a> below (if you
        contact us via email, please use the subject &ldquo;California Eraser
        Law Request&rdquo;). We may not remove your User Content that we are
        required to retain under any federal or state law, or that have been
        provided to a third party. While we will do our best to remove a
        minor&rsquo;s information upon a valid request, we cannot ensure the
        complete or comprehensive removal of your User Content from our Website
        or any information that has been republished, copied, downloaded, or
        reposted by any third party, and we cannot guarantee that any such
        information may not be accessible to users of the internet in the
        future. We do not advertise or market any of the products or services
        identified in California Business and Professionals Code Section
        22580(i) to users who we have actual knowledge are under 18 years of
        age.
      </p>
      <Text center>
        <h3>ADDITIONAL INFORMATION FOR USERS IN THE EUROPEAN ECONOMIC AREA</h3>
      </Text>
      <h4>Personal Information</h4>
      <p>
        References to &ldquo;<u>Personal Information</u>&rdquo; in this Privacy
        Policy are equivalent to &ldquo;<u>personal data</u>&rdquo; governed by
        European data protection legislation.
      </p>
      <h4>Controller</h4>
      <p>
        Slab is the controller of your personal information for purposes of
        European data protection legislation.
      </p>
      <h4>Lawful Basis for Data Processing</h4>
      <p>
        We will only collect, store, and process your personal information where
        a lawful basis for such processing exists, which will typically fall
        under one of the following scenarios:
      </p>
      <ol>
        <li>
          You, the Data Subject, have given consent to the processing of your
          personal information for one or more specific purposes, for example by
          consenting to terms and conditions on our website, and consenting to
          the terms of this Privacy Policy;
        </li>
        <li>
          Processing is necessary for the performance of a contract to which
          you, the Data Subject, are party;
        </li>
        <li>
          Processing is necessary for compliance with a legal obligation to
          which we as a Controller is subject;
        </li>
        <li>
          Processing is necessary for the purposes of the legitimate interests
          pursued by us as the Controller, or by a third party, except where
          such interests are overridden by the interests or fundamental rights
          and freedoms of you, the Data Subject, which require protection of
          your personal information. The legitimate interests that underlie our
          processing of personal data include research and development,
          marketing and promoting the Services, and protecting our legal rights
          and interests.
        </li>
      </ol>
      <h4>Use for New Purposes</h4>
      <p>
        We may use your personal information for reasons not described in this
        Privacy Policy where permitted by law and the reason is compatible with
        the purpose for which we collected it.
      </p>
      <h4>Retention</h4>
      <p>
        We will only retain your personal information for as long as necessary
        to fulfill the purposes we collected it for, including for the purposes
        of satisfying any legal, accounting, or reporting requirements. For
        example, we keep account information for as long as the account is
        active, and for a reasonable period thereafter to ease reactivation if
        you choose to reactivate your account. If the Services are made
        available to you through an organization, e.g., your employer, we retain
        your information as long as required by the administrator of your
        account on their behalf.
      </p>
      <p>
        To determine the appropriate retention period for personal information,
        we consider the amount, nature, and sensitivity of the personal
        information, the potential risk of harm from unauthorized use or
        disclosure of your personal information, the purposes for which we
        process your personal information and whether we can achieve those
        purposes through other means, and the applicable legal requirements. In
        some circumstances we may anonymize your personal information (so that
        it can no longer be associated with you) in which case we may use this
        information indefinitely without further notice to you.
      </p>
      <h4>Your Rights</h4>
      <p>
        European data protection laws give you certain rights regarding your
        personal information. You may ask us to take the following actions in
        relation to your personal information that we hold:
      </p>
      <ul>
        <li>
          &ldquo;<u>Opt-out</u>&rdquo;. Stop sending you direct marketing
          communications. You may continue to receive Service-related and other
          non-marketing emails.
        </li>
        <li>
          &ldquo;<u>Access</u>&rdquo;. Provide you with information about our
          processing of your personal information and give you access to your
          personal information.
        </li>
        <li>
          &ldquo;<u>Correct</u>&rdquo;. Update or correct inaccuracies in your
          personal information.
        </li>
        <li>
          &ldquo;<u>Delete</u>&rdquo;. Delete your personal information.
        </li>
        <li>
          &ldquo;<u>Transfer</u>&rdquo;. Transfer a machine-readable copy of
          your personal information to you or a third party of your choice.
        </li>
        <li>
          &ldquo;<u>Object</u>&rdquo;. Object to our reliance on our legitimate
          interests as the basis of our processing of your personal information
          that impacts your rights.
        </li>
        <li>
          &ldquo;<u>Withdraw Consent</u>&rdquo;. If our processing is based on
          your consent, you have the right to withdraw such consent at any time.
        </li>
      </ul>
      <p>
        You may also have the right to make a GDPR complaint to the relevant
        Supervisory Authority. A list of Supervisory Authorities is available{' '}
        <a
          href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
          rel="noreferrer"
          target="_blank"
        >
          here
        </a>
        .
      </p>
      <p>
        You can submit these requests by email to: {support}. We may request
        specific information from you to help us confirm your identity and
        process your request. Applicable law may require or permit us to decline
        your request. If we decline your request, we will tell you why, subject
        to legal restrictions. If you would like to submit a complaint about our
        use of your personal information or response to your requests regarding
        your personal information, you may contact us as described in Section 13
        of our Privacy Notice or submit a complaint to the data protection
        regulator in your jurisdiction. You can find your data protection
        regulator{' '}
        <a
          href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
          rel="noreferrer"
          target="_blank"
        >
          here
        </a>
        .
      </p>
      <h4>Cross-Border Data Transfer</h4>
      <p>
        Whenever we transfer your personal information out of the EEA to
        countries not deemed by the European Commission to provide an adequate
        level of personal information protection, the transfer will be based on
        either use of approved standard contractual clauses or one of the
        safeguards recognized by the European Commission as providing adequate
        protection for personal information, where required by EU data
        protection legislation.
      </p>
    </section>
    <section>
      <h2 id="privacy-policy-changes">12. Changes to Our Privacy Policy</h2>
      <p>
        We reserve the right to change this Privacy Policy at any time. If we
        decide to change this Privacy Policy in the future, we will post or
        provide appropriate notice. Except as explained below, any non-material
        change (such as clarifications) to this Privacy Policy will become
        effective on the date the change is posted, and any material changes
        will become effective 30 days from their posting on {privacy} or via
        email to your listed email address. Unless stated otherwise, our current
        Privacy Policy applies to all Personal Information that we have about
        you and your account. The date on which the latest update was made is
        indicated at the top of this document. We recommend that you print a
        copy of this Privacy Policy for your reference and revisit this policy
        from time to time to ensure you are aware of any changes. Your continued
        use of the Services signifies your acceptance of any changes.
      </p>
      <p>
        If applicable law requires your opt in consent to any particular
        amendment to this Privacy Policy, the amendment will not apply to your
        Personal Information until we receive such consent from you. To the
        extent we cannot provide some or all of the Services without your
        consent to such amendment to the Privacy Policy, your decision not to
        consent may result in our having to limit your ability to use certain
        aspects of the Services.
      </p>
      <Text center bold>
        <p>
          YOUR CONTINUED USE OF OUR SERVICES FOLLOWING THE POSTING OF CHANGES
          CONSTITUTES YOUR ACCEPTANCE TO SUCH CHANGES.
        </p>
      </Text>
    </section>
    <section>
      <h2 id="contact-information">13. Contact Information</h2>
      <p>
        If you have any questions, concerns, complaints, or suggestions
        regarding our Privacy Policy or the ways in which we collect and use
        your Personal Data described in this Privacy Policy, have any requests
        related to your Personal Data pursuant to applicable laws, or otherwise
        need to contact us, you may contact us at the contact information below
        or through the &ldquo;Contact&rdquo; page on our Website.
      </p>
      <p>
        {name}
        <br />
        {mail}
        <br />
        {support}
      </p>
    </section>
  </LegalLayout>
);

export default Privacy;
